$break-small: 320px;
$break-large: 1024px;
@media screen and (max-height: 680px) {
  section.main .container h1 {
    margin-top: 70px;
  }
  .more-page{
    font-size: 20px;
  }
  .how-it-work{
    background-size: 75%;
    .container{
      margin-bottom: 20px;
    }
  }
  .how-much{
    background-size: 70%;
    line-height: 0.8rem;
    h2{
      margin-top: 70px;
    }
    .big-text{
      font-size: 36px;
    }
    &--price{
      margin-top: 20px;
    }
    &--sale-item{
      min-height: 60px;
    }
    &--zakaz{
      margin-top: 0px;
      font-size: 16px;
    }
  }
  .slick-slide img{
    max-height: 300px;
  }
  .fp-tableCell{}
}

@media screen and (max-width: $break-large) {
  .logo-top{
    display: none;
  }
  .top-menu{
    .lk-btn{
      position: relative;
      margin-top: 9px;z-index: 3;
    }
    menu{
      position: absolute;
      padding-top: 44px;
      left: 0;
      width: 100%;
      z-index: 1;
      margin-top: 17px;
      background: #fff;
      &:before{
        content: '';
        display: block;
        position: absolute;
        height: 20px;
        width: 24px;
        background: url(../img/mobile-menu.svg) left top no-repeat;
        background-size: contain;
        cursor: pointer;
        left: 30px;
        top: 3px;
      }

      &.active{

        li{
          display: block;
          padding: 15px 30px;

        }
      }
      li{
        background: #fff;
        display: none;
        float: none;
        margin: 0;
        &:last-child{
          box-shadow: 0 6px 10px rgba(0,0,0,.2);
        }
      }
    }
  }
  .lk-box{
    .container{
      min-width: auto;
    }
    &--item{
      display: block!important;
    }
    &--item-text{
      padding: 0;
    }
    &--item-image{
      height: 300px;
      img{
        height: 100%;
      }
    }
    .slick-prev{
      left: 0;
    }
  }
  .slick-slide{
    .lk-box--item-image{
      text-align: center;
    }
    img{
      display: inline;
    }
  }
}
@media screen and (max-width: 768px) {
  .lk-box--item-image{
    height: 250px;
  }
  #svgSt1, #svgSt2, #svgSt3{
    width: 76px;
    rect{
      width: 76px;
    }
  }
  .main{
    height: 100vh;
    min-height: 723px;
  }
  .snow{
    display: none;
  }
  section.main .snow{
    width: 728px;
    left: -53px;
    background-size: 760px;
  }
  .how-it-work {
    .container{
      margin-bottom: 0;
    }
  }
  .more-page{
    position: relative;
    bottom: 0;
    margin-top: 20px;
  }
  .how-much{
    background: url(../img/full-circle.svg) center -550px no-repeat;
    h2{
      margin: 0;
      padding-top: 110px;
    }
  }
  .how-it-work{
    h2{
      margin: 0;
    }
    background: url(../img/full-circle.svg) center 220px no-repeat;
    &--list{
      padding-bottom: 30px;
      margin-top: 180px;
    }
  }
  .line{
    display: none
  }
  .mobile-center{
    text-align: center;
  }
}
@media screen and (max-width: 704px) {
  .lk-box--item-image{
    height: 180px;
  }
  .how-it-work--list{
    margin-top: 80px;
  }
  .container{
    padding: 0 10px;
  }
  .lk{
    font-size: 12px;
    padding: 0;
    margin-right: 10px;
  }
  .buttom{
    padding: 0 10px;
  }
  h1, h2{
    font-size: 24px;
  }
  h3{
    font-size: 18px;
  }
  .form--box{
    width: 100%;
    background: url(../img/form-mobile.svg) center no-repeat;
    br{
      display: none;
    }
  }
  .slick-arrow{
    display: none!important;
  }
  .column-graph--percent{
    font-size: 26px;
    line-height: 32px;
  }
  .column-graph--text{
    font-size: 12px;
    line-height: 15px;
  }
  section.main .snow {
    left: 10px;
    background-size: 392px 800px;
  }
  .lk-box{
    &--slide-list{
      margin-top: 10px;
    }
    &--item-text{
      padding: 0 10px;
    }
  }
  .how-it-work--list{
    display: block;
  }
  .how-much--sale{
    width: 100%;
    display: block;
  }
  .iagree-box{
    width: 100%;
  }
  .how-it-work--item{
    font-size: 12px;
    strong{
      font-size: 14px;
      br{
        display: none;
      }
    }
  }
  .how-it-work {
    background: url(../img/full-circle.svg) center 200px no-repeat;
  }
  .top-menu menu{
    &:before{
      width: 14px;
      height: 10px;
      left: 10px;
      top: 10px;
    }
  }
  .top-menu menu li{
    text-align: center;
  }
  .how-much--zakaz{
    .buttom{
      margin-top: 20px;
    }
  }
  .how-much--sale-name span{
    font-size: 18px;
  }
  .how-much--sale-item{
    min-height: 50px;
  }
  .how-much-flex{
    display: flex;
    text-align: center;
    .how-much--sale{
      flex: 1;
    }
    .how-much--sale-call{
      text-align: center;
    }
    .label{
      margin: 0;
    }
    *{
      min-width: auto;
    }
    .how-much--sale-name{
      max-width: 150px;
      margin: 0 auto;
      min-height: auto;
      margin-top: 10px;
      img{
        height: 22px;
        margin-top: 5px;
      }
    }
    .how-much--sale-call{
      max-width: 100px;
      margin: 0 auto;
      sup{
        right: 0;
      }
    }
    .how-much--sale-arrow{
      vertical-align: middle;
      line-height: 50px;
      img{
        vertical-align: middle;
        transform: rotate(90deg);
      }
    }
  }
}
@media screen and (max-width: 370px) {
  .lk-btn{
    .buttom{
      font-size: 9px;
    }
  }
  .form--box{
    background: url(../img/form-mobile.svg) 0 0 no-repeat;
    background-size: contain;
    padding: 40px 40px 0;
  }
  .form--title {
    font-size: 17px;
  }
}
