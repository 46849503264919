.ico-time{
  height: 100px;
  background: url(../img/time.svg) center bottom no-repeat;
  margin-bottom: 30px;
}
.ico-phone{
  height: 100px;
  margin-bottom: 30px;
  background: url(../img/phone.svg) center bottom no-repeat;
}
.ico-eye{
  height: 100px;
  margin-bottom: 30px;
  background: url(../img/eye.svg) center bottom no-repeat;
}