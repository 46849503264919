
@keyframes arrow-scroll {
0% {opacity:0}
40% {opacity:1}
100% {opacity:0}
}
@keyframes snow {
  0% {background-position: -45px -500px; background-repeat: no-repeat;}
  70% {}
  100% {background-position: -45px 500px; background-repeat: no-repeat;}
}
@keyframes coll1 {
  0% {height: 100px;}
  100% {height: 189px;}
}
@keyframes coll2 {
  0% {height: 100px;}
  100% {height: 250px;}
}
@keyframes coll3 {
  0% {height: 100px;}
  100% {height: 278px;}
}

@keyframes showBox {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

#svgSt1{
animation: coll1 6.2s linear;
}
#svgSt2{
animation: coll2 6.2s linear;
}
#svgSt3{
animation: coll3 6.2s linear;
}