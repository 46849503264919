@import "~slick-carousel/slick/slick";
@import "~fullpage.js/dist/fullpage.min.css";
@import "./reset.scss";
@import "./animation";
@import "./icon.scss";
.clearfix{
  clear: both;
  width: 100%;
  height: 1px;
  font-size: 1px;
  float: none;
}
.text-center{
  text-align: center;
}
h1{
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 48px;
  text-align: center;
  color: #130049;
  margin-top: 56px;
  margin-bottom: 38px;
}
h2{
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 36px;
  text-align: center;
  color: #6933FF;
}
h3{
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  font-size: 24px;
  line-height: 29px;
  color: #130049;
  text-align: center;
}
.text-canter{
  text-align: center;
}
.lk-btn{
  float: right;
  margin-top: -15px;
}
.buttom{
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 0 20px;
  color: #fff;
  background: #6933FF;
  text-transform: uppercase;
  border: 2px solid #6933FF;
  border-radius: 8px;
  line-height: 40px;
  height: 40px;
  &_outline{
    color: #6933FF;
    background: #fff;
  }
  &_shadow{
    background: #6933FF;
    border: 2px solid #6933FF;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(105, 51, 255, 0.367272);
    border-radius: 8px;
  }
}
.error{
  color: red;
}
.lk{
  margin-right: 28px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  color: #000;
  text-transform: uppercase;
}
.container{
  max-width: 950px;
  margin: 0 auto;
  padding: 0 38px;
  position: relative;
}
.top-menu{
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  z-index: 10;
  height: 60px;
    menu{
      margin-top: 22px;
      li a{
        font-size: 16px;
      }
    }
    .lk{
      line-height: 40px;
      height: 40px;
    }
    .lk-btn{
      margin-top: -12px;
    }
}
.top-menu .container{
  max-width: 1440px;
  position: relative;
}
.logo-top{
  margin-top: -5px;
  margin-right: 48px
}
.top-menu menu{
  margin-top: 25px;
}
.top-menu menu li{
  list-style: none;
  float: left;
  margin: 0 20px;
}
.top-menu menu li a {
  text-decoration: none;
  font-size: 18px;
  color: #000;
}
.fp-tableCell{
  min-height: 640px!important;
}
section.main{
  // margin-top: 80px;
  // min-height: calc(100vh - 70px);
  overflow: hidden;
  .container{
    h1{
      margin-top: 100px;
    }
  }
  .fp-tableCell{
    vertical-align: top;
  }
  .snow{
    background-image: url('../img/animation1.svg');
    background-position: -1000px;
    background-repeat: no-repeat;
    animation: snow 10s linear; // infinite;
    position: absolute;
    width: 781px;
    height: 373px;
    left: 45px;
    top: -25px;
    z-index: -1;
    opacity: .5;
  }
}

.logo-top{
  float: left;
}
.how-it-work{
  background: url(../img/big-circle.svg) center 110% no-repeat;
  opacity: 0;
  animation-fill-mode: both;
  animation-duration: 2s;
  animation-delay: .5s;
  .container{
    opacity: 0;
    animation-fill-mode: both;
    animation-duration: 2s;
    animation-delay: 1s;
  }
  &.active{
    opacity: 1;
    animation-name: showBox;
    .container{
      opacity: 1;
      animation-name: showBox;
    }
  }
  &--text{
    max-width: 453px;
    margin: 38px auto;
    text-align: center;
  }
  &--list{
    display: flex;
    margin-top: 150px;
  }
  &--item {
    flex: 1;
    padding: 0 24px;
    text-align: center;
    color: #fff;
    strong{
      font-size: 18px;
      font-weight: bold;
      line-height: 21px;
      display: inline-block;
      margin-bottom: 25px;
    }
  }
  .container{
    margin-bottom: 100px;
  }
  .fp-tableCell{
    vertical-align: bottom;
  }
}
.how-much{
  background: url(../img/big-circle-2.svg) center top no-repeat;
  opacity: 0;
  animation-fill-mode: both;
  animation-duration: 2s;
  animation-delay: .5s;
  .container{
    opacity: 0;
    animation-fill-mode: both;
    animation-duration: 2s;
    animation-delay: 1s;
  }
  &.active{
    opacity: 1;
    animation-name: showBox;
    .container{
      opacity: 1;
      animation-name: showBox;
    }
    .how-much--sale-lids{
      opacity: 1;
      animation-name: showBox;
      animation-delay: 2s;
    }
    .how-much--sale-name,.how-much--sale-arrow1{
      opacity: 1;
      animation-name: showBox;
      animation-delay: 2.5s;
    }
    .how-much--sale-call,.how-much--sale-arrow2{
      opacity: 1;
      animation-name: showBox;
      animation-delay: 3.8s;
    }
    sup{
      opacity: 1;
      animation-name: showBox;
      animation-delay: 3.2s;
    }
  }
  h2{
    color: #fff;
    margin-top: 150px;
  }
  sup{
    position: absolute;
    right: 0;
    top: -17px;
    opacity: 0;
    animation-fill-mode: both;
    animation-duration: 1s;
  }
  .label{
    display: block;
    color: #000;
    margin-left: 15px;
    font-size: 14px;
  }
  .color-white{
    color: #fff;
    font-size: 18px;
    text-align: center;
  }
  .big-text{
    font-size: 42px;
    line-height: 51px;
    font-weight: bold;
    color: #6933FF;
  }
  .fp-tableCell{
    vertical-align: top;
  }
  &--price{
    font-style: normal;
    font-weight: bold;
    line-height: normal;
    font-size: 72px;
    text-align: center;
    color: #6933FF;
    text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: 100px;
    margin-bottom: 30px;
  }
  &--zakaz{
    font-size: 18px;
    margin-top: 17px;
    text-align: center;
  }
  &--sale{
    width: 640px;
    margin: 0 auto;
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
  }
  &--sale-lids{
    min-width: 178px;
    position: relative;
  }
  &--sale-name{
    min-width: 257px;
    text-align: center;
    position: relative;
    span{
      font-size: 24px;
      line-height: 29px;
      font-weight: bold;
    }
  }
  &--sale-call{
    min-width: 181px;
    text-align: right;
    position: relative;
    sup{
      right: -25px;
    }
  }
  &--sale-arrow{
    min-width: 12px;
  }
  &--sale-item{
    flex: 1;
    opacity: 0;
    animation-fill-mode: both;
    animation-duration: 1s;
    min-height: 70px;
  }
}

.line{
  width: 100%;
  background: #6933FF;
  height: 1px;
  margin: 12px auto 25px;
}
.column-graph{
  display: flex;
  align-items: flex-end;
  min-height: 354px;
  position: relative;
  &--item{
    flex: 1;
    text-align: center;
  }
  &--percent{
    color: #6933FF;
    font-size: 42px;
    line-height: 51px;
    font-weight: bold;
  }
  &--text{
    font-size: 18px;
    line-height: 21px;
  }
}
#svgSt1{
  height: 189px;
}
#svgSt2 {
  height: 250px;
}
#svgSt3 {
  height: 278px;
}
.more-page{
  display: block;
  text-decoration: none;
  color: #000;
  text-align: center;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  position: absolute;
  cursor: pointer;
  bottom: 12px;
  width: 100%;
  &--arrow{
    text-align: center;
    margin-top: 10px;
    svg path {
      animation: arrow-scroll 2.5s infinite;
    }
    .arrow1{
      animation-delay:-1s;
    }
    .arrow1{
      animation-delay:-.5s;
    }
  }
}
.lk-box{
  margin-top: 126px;
  h2{
    color: #130049;
  }
  h3{
    color: #6933FF;
    text-align: left;
    margin-bottom: 20px;
  }
  .container{
    min-width: 1236px;
  }
  &--slide-list{
    margin-top: 70px;
  }
  &--item{
    display: flex!important;
  }
  &--item-text{
    flex: 1;
    padding-right: 45px;
    padding-left: 15px;
  }
  &--item-image{
    flex: 1;
  }
  .slick-arrow{
    position: absolute;
    top: 50%;
    z-index: 9;
    width: 23px;
    height: 41px;
    border: 0;
    overflow: hidden;
    text-indent: -100px;
    margin-top: -20px;
    cursor: pointer;
    outline: none;
    background: url(../img/arrow1-big.svg) left top no-repeat;
  }
  .slick-prev{
    left: -30px;
    transform: rotate(180deg);
  }
  .slick-next{
    right: 0;
  }
  .slick-dots{
    display: flex;
    justify-content: center;
    margin-top: 30px;
    li{
      button{
        display: block;
        width: 10px;
        height: 10px;
        overflow: hidden;
        text-indent: -100px;
        padding: 0;
        margin: 0 5px 0 0;
        border: 0;
        border-radius: 50%;
        background: #D8D8D8;
        outline: none;
        cursor: pointer;
      }
      &.slick-active{
        button{
          background: #6933FF;
        }
      }
    }
  }
}
.form{
  margin-top: 126px;
  &--box{
    width: 600px;
    height: 380px;
    margin: 0 auto;
    background: url(../img/form-bg.svg) left top no-repeat;
    background-size: contain;
    padding: 40px 70px 0 70px;
  }
  &--title{
    font-size: 21px;
    line-height: 24px;
    font-weight: bold;
    word-wrap: break-word;
  }
  &--input{
    margin: 30px 0;
    text-indent: 20px;
    width: 100%;
    border: 0;
    font-size: 18px;
    line-height: 22px;
    padding-bottom: 10px;
    border-bottom: 2px solid #6933FF;
    &:focus{
      outline: none;
    }
  }
}
.iagree-box{
  width: 600px;
  margin: 20px auto;
  font-size: 12px;
  line-height: 15px;
}
.style-checkbox{
  position: relative;
  display: block;
  padding-left: 52px;
  [type="checkbox"]{
    display: none;
  }
  .checkmark{
    position: absolute;
    left: 10px;
    width: 30px;
    height: 30px;
    display: inline-block;
    background: #6933FF;
    border: 2px solid #6933FF;
    box-sizing: border-box;
    box-shadow: 0px 2px 10px rgba(105, 51, 255, 0.367272);
    border-radius: 8px;
  }
  input:checked ~ .checkmark::before{
    content: '';
    display: block;
    background: url(../img/сheck.svg) center center no-repeat;
    width: 30px;
    height: 30px;
  }
}
.slick-slide img{
  position: relative;
  top: -2px;
  max-width: 100%;
}
menu.active:before{
  background: url(../img/close-menu.svg) left top no-repeat;
}
section.news{
  text-align: left;
  .first-element{
    margin-top: 100px;
    text-transform: uppercase;
  }
  h1{
    text-align: left;
  }
  h2{
    text-align: left;
    line-height: 40px;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  h3 {
    text-align: left;
    font-weight: 500;
    margin: 29px 0 20px 0;
  }
  a{
    text-decoration: none;
  }
  .top-news{
    border-top: 1px solid #D8D8D8;
    border-bottom: 1px solid #D8D8D8;
    padding-bottom: 40px;
  }
  .text{
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
  }
  .news-list{
    li{
      overflow: hidden;
      margin-bottom: 20px;
    }
    .img-news{
      float: left;
      margin-right: 20px;
    }
    a{
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      color: #6933FF;
      text-decoration: none;
    }
  }
  .soc-news{
    float: right;
  }
  .news-bg-text{
    line-height: 30px;
    font-weight: 500;
    font-size: 18px;
    background: rgba(105, 51, 255, .2);
    display: inline-block;
    margin: 20px 0;
  }
  .img-align-left{
    float: left;
    margin: 0 30px 20px 0;
  }
  .color-list{
    margin: 20px 0;
    li{
      padding-left: 20px;
      position: relative;
      line-height: 23px;
      margin-bottom: 20px;
      &:before{
        content: '';
        display: block;
        position: absolute;
        left: 2px;
        top: 50%;
        margin-top: -3px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #6933FF;
      }
    }
  }
  .form{
    margin-top: 0;
    h1{display: none;}
  }
}
.border-grey{
  border-bottom: 1px solid #D8D8D8;
  margin: 30px 0;
}
.text-bold{
  font-weight: bold;
}
.text-align-center{
  text-align: center;
}
@import "./adaptive";
